import * as React from "react";
import axios from "axios";
import { push, graphql } from "gatsby";
import Select from "react-select";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Redirect from "../../components/Redirect";
import Form from "../../components/form/form";
import Spinner from "../../components/spinner/spinner";
import { BrandButton } from "../../lib/components/Button/BrandButton";
import {
    isLoggedIn,
    getUser,
    updateUser,
    getAccessToken,
} from "../../utils/auth";
import { urls } from "../../config";
import { css } from "@emotion/core";
import AccountLayout from "./account-layout";
import Card from "./card";
import InternalPage from "./internal-page";
import localize from "../../hoc/Localize";
import "./profile.scss";

import ReactDOM from "react-dom";

const selectStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: "white",
        "font-family": "Barlow",
        "font-size": "17px",
        "font-weight": "normal",
        "font-style": "normal",
        "font-stretch": "normal",
        "letter-spacing": "normal",
        color: "#000000",
        "vertical-align": "top",
        height: "30px",
        "justify-content": "flex-start",
        div: {
            padding: 0,
        },
        "div:first-child": {
            "flex-grow": "1",
        },
        "div:nth-child(2)": {
            "flex-grow": "4",
            "max-width": "16px",
            // 'svg path': {
            //   fill: 'red',
            // },
        },
        border: 0,
        borderColor: "blue",
        boxShadow: "none",
        "border-radius": 0,
        span: {
            display: "none",
        },
        svg: {
            path: {
                fill: "#000",
            },
            height: "100%",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#fff" : "#353e44",
        backgroundColor: state.isSelected
            ? "#00B985"
            : state.isFocused
            ? "#00B985"
            : "#fff",
    }),
};

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUser(),
            // Warning: switch from uncontrolled to controlled fix.
            newPassword: "",
            loadingPassword: false,
            loadingProfile: false,
            oldPassword: "",
            confirmPassword: "",
            passworderror: "",
        };
        this.selectRef = React.createRef();
        this.formRef = React.createRef();
        // this.handleSubmit =
    }

    onSubmitNewPassword = () => {
        const { newPassword, oldPassword, confirmPassword } = this.state;
        const { changePassword } = this.props.data.sanityAccount;

        if (newPassword !== confirmPassword) {
            this.setState({
                // toast: changePassword.unmatch,
                passworderror: changePassword.unmatch,
            });
            // Handle validation error here.
            return;
        }

        if (!oldPassword) {
            // Handle validation error here.
            this.setState({
                // toast: changePassword.required,
                passworderror: changePassword.required,
            });
            return;
        }

        const formData = { oldPassword, newPassword };
        this.setState({ loadingPassword: true });
        const _self = this;
        axios({
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`,
            },
            url: `${urls.changePassword}`,
            data: formData,
        })
            .then(res => {
                if (res.data.error) throw new Error(res.data.error);
                // Handle API error reporting here.
                // alert('OK!' + res.data.result);
                if (res.data.result) {
                    this.setState({
                        toast: changePassword.saved,
                        passworderror: "",
                        loadingPassword: false,
                    });
                    ReactDOM.findDOMNode(_self).scrollIntoView();
                }
            })
            .catch(err => {
                // alert('error: ' + err);
                this.setState({
                    // toast: changePassword.failed,
                    passworderror: changePassword.failed,
                    loadingPassword: false,
                }); // @TODO: get copy for this err msg.
            });
    };

    onChange = ({ target: { name, type, checked, value } }) => {
        const user = Object.assign({}, this.state.user, {
            [name]: type === "checkbox" ? checked : value,
        });
        this.setState({ user });
    };

    passwordFieldChange = e => {
        const field = e.target.name;
        const val = e.target.value;
        const update = {};
        update[field] = val;
        this.setState(update);
    };

    // Handle submit of updated user profile data.
    handleSubmit = e => {
        e.preventDefault();
        const _self = this;
        this.setState({ loadingProfile: true });
        const profileData = this.props.data.sanityAccount.profile;
        updateUser(this.state.user)
            .then(() => {
                this.setState({
                    toast: profileData.saved,
                    profileerror: "",
                    loadingProfile: false,
                });
                ReactDOM.findDOMNode(_self).scrollIntoView();
            })
            .catch(err => {
                this.setState({
                    toast: "",
                    profileerror: profileData.failed,
                    loadingProfile: false,
                });
            });
    };

    submitPasswordForm = e => {
        this.formRef.current.onSubmit(e);
    };

    // handleChangeUnitsHeight = e => {
    //   this.setState({ heightUnits: e.target.value });
    // };

    // handleChangeUnitsWeight = e => {
    //   this.setState({ weightUnits: e.target.value });
    // };

    render() {
        if (!isLoggedIn()) return <Redirect to="/login" />;
        const profileData = this.props.data.sanityAccount.profile;
        const accountData = this.props.data.sanityAccount.account;
        const changePassword = this.props.data.sanityAccount.changePassword;
        const { user } = this.state;

        return (
            <PageWrapper>
                <AccountLayout currentPage="/account/profile">
                    <div className="">
                        <Card titleText={profileData.title}>
                            <form
                                className="profile-content-block__form"
                                onSubmit={this.handleSubmit}
                            >
                                <Spinner active={this.state.loadingProfile} />
                                <p className="account__text-input-label">
                                    {profileData.firstName}
                                </p>
                                <input
                                    className="account__text-input-field"
                                    placeholder=""
                                    id="firstName"
                                    name="firstName"
                                    value={user.firstName}
                                    onChange={this.onChange}
                                />

                                <p className="account__text-input-label">
                                    {profileData.lastName}
                                </p>
                                <input
                                    className="account__text-input-field"
                                    placeholder=""
                                    id="lastName"
                                    name="lastName"
                                    value={user.lastName}
                                    onChange={this.onChange}
                                />

                                <p className="account__text-input-label">
                                    {accountData.email}
                                </p>
                                <input
                                    className="account__text-input-field"
                                    placeholder=""
                                    name="username"
                                    value={user.username}
                                    onChange={this.onChange}
                                    disabled="disabled"
                                />

                                <p className="account__text-input-label">
                                    {profileData.gender}
                                </p>
                                <Select
                                    className="genders"
                                    styles={selectStyles}
                                    ref={this.selectRef}
                                    id="gender"
                                    name="gender"
                                    isSearchable={false}
                                    options={profileData.genderOptions}
                                    onChange={e =>
                                        this.onChange({
                                            target: { name: "gender", ...e },
                                        })
                                    }
                                    value={profileData.genderOptions.find(
                                        option => {
                                            return option.value === user.gender;
                                        }
                                    )}
                                />
                                {this.state.profileerror ? (
                                    <div
                                        css={css`
                                            height: 48px;
                                            font-family: Barlow;
                                            font-size: 18px;
                                            font-weight: normal;
                                            font-style: normal;
                                            font-stretch: normal;
                                            line-height: 1.33;
                                            letter-spacing: normal;
                                            text-align: center;
                                            color: #fc172f;
                                        `}
                                    >
                                        {this.state.profileerror}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div
                                    css={css`
                                        display: block;
                                        /* margin-left: -20px; */
                                        @media (max-width: 599px) {
                                            display: flex;
                                        }
                                    `}
                                >
                                    <BrandButton
                                        className={
                                            "header__nav-button button-hover "
                                        }
                                        css={css`
                                            margin-top: 38px;
                                        `}
                                    >
                                        {profileData.save}
                                    </BrandButton>
                                </div>
                            </form>
                        </Card>

                        <Card
                            style={{ marginLeft: "40px" }}
                            titleText={changePassword.reset}
                        >
                            <Spinner active={this.state.loadingPassword} />
                            <Form
                                className="change-password__form"
                                onSubmit={this.onSubmitNewPassword}
                                ref={this.formRef}
                            >
                                <input
                                    className="account__text-input-field"
                                    placeholder={changePassword.old}
                                    name="oldPassword"
                                    type="password"
                                    onChange={this.passwordFieldChange}
                                    value={this.state.oldPassword}
                                />
                                <input
                                    className="account__text-input-field"
                                    placeholder={changePassword.new}
                                    name="newPassword"
                                    type="password"
                                    onChange={this.passwordFieldChange}
                                    value={this.state.newPassword}
                                />
                                <input
                                    className="account__text-input-field bottom-input"
                                    placeholder={changePassword.repeat}
                                    name="confirmPassword"
                                    type="password"
                                    onChange={this.passwordFieldChange}
                                    value={this.state.confirmPassword}
                                />
                                {this.state.passworderror ? (
                                    <div
                                        css={css`
                                            height: 48px;
                                            font-family: Barlow;
                                            font-size: 12px;
                                            font-weight: normal;
                                            font-style: normal;
                                            font-stretch: normal;
                                            line-height: 18px;
                                            letter-spacing: normal;
                                            text-align: left;
                                            color: #fc172f;
                                        `}
                                    >
                                        {this.state.passworderror}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div
                                    className={
                                        "mt-8 change-password__form-controls "
                                    }
                                    css={css`
                                        display: block;
                                        @media (max-width: 599px) {
                                            display: flex;
                                        }
                                    `}
                                >
                                    <BrandButton
                                        className={
                                            "header__nav-button button-hover"
                                        }
                                        css={css`
                                            margin-top: 12px;
                                        `}
                                        onClick={this.submitPasswordForm}
                                    >
                                        {changePassword.save}
                                    </BrandButton>
                                </div>
                            </Form>
                        </Card>
                    </div>
                </AccountLayout>
            </PageWrapper>
        );
    }
}

export const query = graphql`
    query profileQuery {
        sanityAccount {
            profile {
                title {
                    _type
                    en
                    es
                }
                edit {
                    _type
                    en
                    es
                }
                change {
                    _type
                    en
                    es
                }
                update {
                    _type
                    en
                    es
                }
                firstName {
                    _type
                    en
                    es
                }
                lastName {
                    _type
                    en
                    es
                }
                gender {
                    _type
                    en
                    es
                }
                save {
                    _type
                    en
                    es
                }
                saved {
                    _type
                    en
                    es
                }
                failed {
                    _type
                    en
                    es
                }
                cancel {
                    _type
                    en
                    es
                }
                genderOptions {
                    value
                    label {
                        _type
                        en
                        es
                    }
                }
                fitnessGoalOptions {
                    value
                    label {
                        _type
                        en
                        es
                    }
                }
            }
            changePassword {
                old {
                    _type
                    en
                    es
                }
                new {
                    _type
                    en
                    es
                }
                save {
                    _type
                    en
                    es
                }
                repeat {
                    _type
                    en
                    es
                }
                reset {
                    _type
                    en
                    es
                }
                unmatch {
                    _type
                    en
                    es
                }
                required {
                    _type
                    en
                    es
                }
                saved {
                    _type
                    en
                    es
                }
                failed {
                    _type
                    en
                    es
                }
            }
            account {
                email {
                    _type
                    en
                    es
                }
            }
        }
    }
`;

export default localize(Profile);
